import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Stickyroll } from '@stickyroll/stickyroll';
// import pic1 from '../../images/pic01.jpg';
// import pic2 from '../../images/pic02.jpg';
// import pic3 from '../../images/pic03.jpg';

// import Feature from './Feature';
const classes = ['howitWorks', 'howitWorks2', 'howitWorks3', 'howitWorks4'];
const content = [
  'We Pick up your dirty clothes',
  'We wash them nice and clean',
  'Press and fold them',
  'Deliver them at your doorsteps',
];
const heading = [
  'How it Works?',
  'We wash them nice and clean',
  'Press and fold them',
  'Deliver them at your doorsteps',
];

const scrolltext = {
  width: '228px',
  'line-height': '1.55em',
};

const Features = () => (
  <Stickyroll pages={classes}>
    {({ page, pageIndex, pages, progress }) => {
      return (
        <Container fluid id={classes[pageIndex]}>
          <Row className={'features-container'}>
            <div class="text-container">
              <h1 class="section-title">{heading[0]}</h1>
              <Col
                md={12}
                lg={12}
                className={'active'}
                style={{ display: 'flex' }}
              >
                <br />
                <span class="section-para" style={scrolltext}>
                  {content[pageIndex]}
                </span>
              </Col>
            </div>
            {/* <Scroll {display: }> content </Scroll> */}
          </Row>
        </Container>
      );
    }}
  </Stickyroll>
);

export default Features;
