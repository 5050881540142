import React from 'react';
import Scroll from '../common/Scroll';
import Scheduler from '../common/scheduler';

const Flipp = () =>
  <section id="flippIntro" className="wrapper style1 fullscreen fade-up">
    <div id="fixedContainer" className={'flippOfferText'}>
    <div>40lb/month free
      {/* <span className={'pricing'} style={{fontSize: '40px'}}><b>$1.49/lb</b></span> */}
    </div>
    </div>
    <Scheduler code={"flipp"} />
    
  </section>


export default Flipp;
